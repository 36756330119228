import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import Banner from "../../components/banner/banner"
import Links from "../../components/about/links/links"

export default function ComplaintsProcedure({ data }) {
  return (
    <Layout>
      <SEO title="Complaints Procedure" />
      <Banner
        image={
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        }
        title="About Us"
      />
      <div className="width75">
        <h1 className="title">Complaints Procedure</h1>
        <Links type="about" />
        <div className="ribbon-top">
          <p>
            JJ Law Chambers is committed to providing a high standard of legal
            service to all its clients.
          </p>
          <p>
            If, however, you are not satisfied with any aspect of our service,
            complaint handling procedures are in place to deal with your
            concerns and a copy of our full written Complaints Policy and
            Procedure is available on request and can be obtained by contacting
            us on{" "}
            <a href="tel:020 3581 2854" className="link">
              <b>020 3581 2854</b>
            </a>
            . If there is cause for a complaint, we would request that
            complaints are made in writing and addressed to:
          </p>
          <div className="center">
            <p>
              Safdar Ali Khan
              <br /> JJ Law Chambers
              <br /> 10A, Station Road
              <br /> Hayes, Middlessex
              <br /> London
              <br /> UB3 4DA
            </p>
          </div>
          <p>
            Alternatively, please contact us on{" "}
            <a href="tel:020 3581 2854" className="link">
              <b>020 3581 2854</b>
            </a>{" "}
            and ask to be put through to the Safdar Ali Khan. Your complaint
            will be formally acknowledged and the full procedure that will be
            followed in dealing with your complaint will be explained to you at
            that time.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "lawers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
